import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteDSSTopicAsync,
  getDatabaseServiceAsync,
  getDSSTopicsAsync,
  handleToggleDSSTopicModal,
  selectDatabaseService,
  selectDSStopics,
  selectDSSTopicsActionLoading,
  selectDSSTopicsLoading,
} from "../../../store/database/serviceSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Typography,
} from "djuno-design";
import HighlighterText from "../../general/HighlighterText";
import { useSearch } from "../../../providers/SearchProvider";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { DeleteModal } from "../../modals/QuestionModal";
import { Helmet } from "react-helmet";
import { getDataStreamServicesAsync } from "../../../store/data-sream/dataStreamServicesSlice";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import { DataStreamGeneralInformationUrl } from "../../../utils/urls";
import { Topic } from "../../../types/data-straming";
import { TopicCreateModal } from "./TopicCreateModal";

const TopicsTab = () => {
  const [filteredTopics, setFilteredTopics] = useState<Topic[]>([]);
  const [deleteTopic, setDeleteTopic] = useState<Topic | null>(null);

  const service = useAppSelector(selectDatabaseService);
  const topics = useAppSelector(selectDSStopics);
  const loading = useAppSelector(selectDSSTopicsLoading);
  const topicsActionLoading = useAppSelector(selectDSSTopicsActionLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().topics;
      if (!permissions.includes(service.engine)) {
        navigate(DataStreamGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDSSTopicsAsync({
            engine: service.engine,
            clusterId: service.id,
          })
        );
      }
    }
  }, [dispatch, navigate, searchValue, service]);

  useEffect(() => {
    if (topics && topics.length > 0) {
      const lookedUpFiles = topics.filter((a) =>
        a.name?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredTopics(lookedUpFiles);
    }
  }, [dispatch, searchValue, topics]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center justify-between">
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Typography.Title level={5} className="!mb-0"></Typography.Title>
            </div>
            <div className="flex items-center gap-2">
              <Button
                uiType="light"
                onClick={() => {
                  if (service) {
                    dispatch(getDataStreamServicesAsync({}));
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                  }
                }}
                disabled={!service}
                className="group"
                tooltip={{ content: "Refresh" }}
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
              <Button
                uiType="primary"
                onClick={() => dispatch(handleToggleDSSTopicModal(true))}
                className="group"
              >
                Add a new entry
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </div>

          <div className="mt-5 w-full database-table">
            <SimpleTable loading={loading} containerClassName="min-h-[240px]">
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Topic " />
                  <SimpleTable.TH lable="Partitions " />
                  <SimpleTable.TH lable="Replication" />
                  <SimpleTable.TH lable="Minimum in-sync replica " />
                  <SimpleTable.TH lable="Retention time (hours)" />
                  <SimpleTable.TH lable="Retention size (bytes) " />
                  <SimpleTable.TH lable="Deletion policy" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>
              <SimpleTable.Body>
                {filteredTopics.map((t, i) => (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD>
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <Flex>
                          <HighlighterText
                            searchValue={searchValue}
                            textToHighlight={t.name}
                            className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                          />
                        </Flex>
                      </div>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {t.partitions}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {t.replication}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {t.minInsyncReplicas}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {t.retentionHours === -1
                          ? "unlimited"
                          : t.retentionHours}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {t.retentionBytes === -1
                          ? "unlimited"
                          : t.retentionBytes}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        delete
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD className="w-20 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-end gap-1">
                        <div className="w-8 flex justify-center items-center">
                          <Dropdown
                            anchor="bottom end"
                            itemsClassName="!w-48"
                            menu={[
                              {
                                key: "end",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <ArchiveIcon className="w-4" />
                                    Delete
                                  </div>
                                ),
                                danger: true,
                                onClick: (_, close) => {
                                  close();
                                  setDeleteTopic(t);
                                },
                                // disabled: ,
                              },
                            ]}
                          >
                            <Button
                              uiType="icon"
                              uiSize="small"
                              className="!px-2"
                            >
                              <MoreIcon className="w-4 h-4" />
                            </Button>
                          </Dropdown>
                        </div>
                        {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                      </div>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                ))}

                {topics.length === 0 && (
                  <SimpleTable.Row
                    withoutHoverStyle={true}
                    className="h-[200px]"
                  >
                    <SimpleTable.TD colSpan={10} className="!border-0">
                      <EmptyState text="You have not created any Topic yet" />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
          <TopicCreateModal />
          <DeleteModal
            title="Delete topic"
            isOpen={deleteTopic !== null}
            onClose={() => setDeleteTopic(null)}
            description=""
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            onConfirm={() => {
              deleteTopic &&
                service &&
                dispatch(
                  deleteDSSTopicAsync({
                    clusterId: service.id,
                    engine: service.engine,
                    topicId: deleteTopic.id,
                  })
                ).then((action) => {
                  if (action.type === "service/topics/delete/fulfilled") {
                    dispatch(
                      getDSSTopicsAsync({
                        engine: service.engine,
                        clusterId: service.id,
                      })
                    ).then((action) => {
                      if (action.payload && "topics" in action.payload) {
                        setFilteredTopics(action.payload.topics);
                      }
                    });

                    setDeleteTopic(null);
                  }
                });
            }}
            loading={topicsActionLoading}
            confirmString={deleteTopic?.name}
          />
        </>
      )}
    </>
  );
};

export default TopicsTab;
