import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import {
  DatabaseService,
  DatabaseServiceState,
  IDBSUser,
  IpRestriction,
  DBSLog,
  IDBSCreateUser,
  IDBSMetricData,
  DBSDatabase,
  IDBSQueryStatisticts,
  IDBSBackup,
  DBSCurrentQuery,
  DBSIntegration,
  DBS_ACC,
  DBSPool,
  IDBSCertificate,
  IDBSNamespaces,
  DBSIntegrationCapability,
} from "../../types/database";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  getDBSLogsApi,
  getDatabaseServiceApi,
  getDBSMaintenanceApi,
  getDBSUserApi,
  resetDBSUserApi,
  createDBSUserApi,
  deleteDBSUserApi,
  getDBSAvalibleMetricsApi,
  getDBSMetricValuesApi,
  getDBSDatabasesApi,
  createDBSDatabasesApi,
  deleteDBSDatabasesApi,
  getDBSQuerrStatisticsApi,
  resetDBSQuerrStatisticsApi,
  getDBSCurrentQueriesApi,
  getDBSServiceIntegrationsApi,
  deleteDBSServiceIntegrationApi,
  getDBSBackupsApi,
  addDBSServiceIntegrationApi,
  etDBSAvancedConfigurationCapabilitiesApi,
  getDBSAvancedConfigurationsApi,
  updateDBSAvancedConfigurationsApi,
  getDBSPoolsApi,
  createDBSPoolApi,
  deleteDBSPoolApi,
  updateDBSPoolApi,
  getDBSCertificateApi,
  getDBSNamespacesApi,
  createDBSNamespaceApi,
  updateBSNamespaceApi,
  deleteBSNamespaceApi,
  getDBSServiceIntegrationCapabilitiesApi,
  DBSRestoreApi,
  updateDBSUserApi,
  deleteDBSNodeApi,
  addDBSNodeApi,
  getDSSReplicationsApi,
  deleteDSSReplication,
  getDSSConnectorsApi,
  deleteDSSConnector,
  getDSSACLsApi,
  getDSSACLApi,
  createDSSACLApi,
  deleteDSSACLApi,
  getDSSTopicsApi,
  createDSSTopicApi,
  deleteDSSTopicsApi,
} from "../../apis/databaseAPI";
import {
  ACL,
  Connector,
  ReplicationFlow,
  Topic,
} from "../../types/data-straming";

const initialState: DatabaseServiceState = {
  service: null,
  maintenance: null,
  users: [],
  usersLoading: false,
  loading: false,
  actionLoading: false, //for create, delete, rename
  showEditorModal: false,
  selectedIP: null,
  showChangeNameModal: false,

  //pools
  pools: [],
  poolsLoading: false,
  poolsActionLoading: false,
  poolsShowEditor: false,
  selectedPool: null,
  poolsShowInfo: false,

  //logs
  logs: [],
  logsLoading: false,

  //user
  user: null,
  userLoading: false,
  showUserDetailsModal: false,
  showUserResetPasswordModal: false,

  //metrics
  metrics: {},
  metricsLoading: false,

  //databases
  databases: [],
  databasesLoading: false,
  databaseShowEditor: false,
  databasesActionLoading: false,

  //queryStatisticts
  queryStatisticts: [],
  queryStatistictsLoading: false,

  //queries in progress
  currentQueries: [],
  currentQueriesLoading: false,

  //service integration
  integrations: [],
  integrationsLoading: false,
  integrationShowEditor: false,
  integrationsActionLoading: false,
  integrationsCapabilities: [],
  integrationsCapabilitiesLoading: false,

  //backups
  backups: [],
  backupsloading: false,

  //advanced configurations
  advancedConfigurationsCapabilities: [],
  advancedConfigurationsCapabilitiesLoading: false,
  advancedConfigurations: {},
  advancedConfigurationsLoading: false,
  advancedConfigurationsActionLoading: false,

  //certificate
  certificate: null,
  certificateLoading: false,

  //namespaces
  namespaces: [],
  namespacesLoading: false,
  namespaceShowEditor: false,
  selectedNamespace: null,

  //nodes
  nodesActionLoading: false,

  //replications
  replications: [],
  replicationsLoading: false,
  replicationsActionLoading: false,

  //connectors
  connectors: [],
  connectorsLoading: false,
  connectorsActionLoading: false,

  //acl
  acls: [],
  aclsLoading: false,
  aclsActionLoading: false,
  showAclEditorModal: false,

  acl: null,
  aclLoading: false,

  //topics
  topics: [],
  topicsLoading: false,
  topicsActionLoading: false,
  showTopicEditorModal: false,
};

export const getDatabaseServiceAsync = createAsyncThunk<
  { service: DatabaseService },
  { id: string; withoutLoading?: boolean; engine?: string },
  IThunkRejectValue
>(
  "service",
  async (
    { id, withoutLoading = false, engine },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getDatabaseServiceAsync.pending(requestId, {
          id,
          withoutLoading,
          engine,
        })
      );
      const response = await getDatabaseServiceApi(id, engine);
      const service = response.data.Result;
      return fulfillWithValue({ service });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSMaintenanceAsync = createAsyncThunk<
  { maintenance: any },
  { id: string; engine: string },
  IThunkRejectValue
>(
  "maintenance",
  async ({ id, engine }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDBSMaintenanceApi(id, engine);
      const maintenance = response.data.Result;
      return fulfillWithValue({ maintenance });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSUsersAsync = createAsyncThunk<
  { users: IDBSUser[] },
  { id: string; engine: string },
  IThunkRejectValue
>(
  "service/users",
  async ({ id, engine }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDBSUserApi(id, engine);
      const users = response.data.Result;
      return fulfillWithValue({ users });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const resetDBSUsersAsync = createAsyncThunk<
  any,
  { id: string; engine: string; userId: string },
  IThunkRejectValue
>(
  "service/users/reset",
  async ({ id, engine, userId }, { rejectWithValue }) => {
    try {
      const response = await resetDBSUserApi(id, engine, userId);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSLogsAsync = createAsyncThunk<
  { logs: Array<DBSLog> },
  { engine: string; clusterId: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "service/logs",
  async (
    { engine, clusterId, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getDBSLogsAsync.pending(requestId, {
          engine,
          clusterId,
          withoutLoading,
        })
      );

      const response = await getDBSLogsApi(engine, clusterId);
      const logs = response.data.Result;

      return fulfillWithValue({ logs });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSMetricValuesAsync = createAsyncThunk<
  { metricValues: Record<string, IDBSMetricData> },
  {
    engine: string;
    clusterId: string;
    period: string;
    withoutLoading?: boolean;
    withoutError?: boolean;
  },
  IThunkRejectValue
>(
  "service/metrics",
  async (data, { rejectWithValue, fulfillWithValue, dispatch, requestId }) => {
    const { engine, clusterId, period, withoutError } = data;
    try {
      //
      dispatch(getDBSMetricValuesAsync.pending(requestId, data));

      const avalibilityResponse = await getDBSAvalibleMetricsApi(
        engine,
        clusterId
      );
      const metricNames = avalibilityResponse.data.Result as string[];
      const promises = metricNames.map(async (metricName) => {
        return await getDBSMetricValuesApi(
          engine,
          clusterId,
          metricName,
          period
        );
      });
      if (promises.length > 0) {
        const results = await Promise.all(promises);

        let metricValues: Record<string, IDBSMetricData> = {};
        for (let i = 0; i < results.length; i++) {
          const data = results[i].data.Result;
          metricValues[data.name] = data;
        }
        return fulfillWithValue({ metricValues });
      } else {
        return fulfillWithValue({ metricValues: {} });
      }
    } catch (e) {
      console.log("hi I'm here");
      if (withoutError) {
        return fulfillWithValue({ metricValues: {} });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const createDBSUserAsync = createAsyncThunk<
  { user: IDBSUser },
  { engine: string; id: string; data: IDBSCreateUser },
  IThunkRejectValue
>(
  "service/user/create",
  async ({ engine, id, data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await createDBSUserApi(engine, id, data);
      const user = response.data.Result;
      return fulfillWithValue({ user });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateDBSUserAsync = createAsyncThunk<
  { user: IDBSUser },
  { engine: string; id: string; userId: string; data: any },
  IThunkRejectValue
>(
  "service/user/update",
  async (
    { engine, id, userId, data },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await updateDBSUserApi(engine, id, userId, data);
      const user = response.data.Result;
      return fulfillWithValue({ user });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteDBSUserAsync = createAsyncThunk<
  any,
  { engine: string; id: string; userId: string },
  IThunkRejectValue
>(
  "service/user/delete",
  async ({ engine, id, userId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await deleteDBSUserApi(engine, id, userId);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSDatabasesAsync = createAsyncThunk<
  { databases: Array<DBSDatabase> },
  {
    engine: string;
    clusterId: string;
  },
  IThunkRejectValue
>("service/databases", async (data, { rejectWithValue, fulfillWithValue }) => {
  try {
    const { engine, clusterId } = data;
    const response = await getDBSDatabasesApi(engine, clusterId);
    const databases = response.data.Result;
    return fulfillWithValue({ databases });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createDBSDatabasesAsync = createAsyncThunk<
  boolean,
  {
    engine: string;
    clusterId: string;
    data: any;
  },
  IThunkRejectValue
>(
  "service/databases/create",
  async ({ engine, clusterId, data }, { rejectWithValue }) => {
    try {
      await createDBSDatabasesApi(engine, clusterId, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteDBSDatabasesAsync = createAsyncThunk<
  boolean,
  {
    engine: string;
    clusterId: string;
    databaseId: string;
  },
  IThunkRejectValue
>(
  "service/databases/delete",
  async ({ engine, clusterId, databaseId }, { rejectWithValue }) => {
    try {
      await deleteDBSDatabasesApi(engine, clusterId, databaseId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSQuerrStatisticsAsync = createAsyncThunk<
  { querrStatistics: IDBSQueryStatisticts[] },
  { engine: string; id: string },
  IThunkRejectValue
>(
  "service/querrStatistics",
  async ({ engine, id }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getDBSQuerrStatisticsApi(engine, id);
      const querrStatistics = response.data.Result;
      return fulfillWithValue({ querrStatistics });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const resetDBSQuerrStatisticsAsync = createAsyncThunk<
  // { querrStatistics: IDBSQueryStatisticts[] },
  any,
  { engine: string; id: string },
  IThunkRejectValue
>(
  "service/querrStatistics/reset",
  async ({ engine, id }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await resetDBSQuerrStatisticsApi(engine, id);
      return response.data;
      // const querrStatistics = response.data.Result;
      // return fulfillWithValue({ querrStatistics });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSCurrentQuerriesAsync = createAsyncThunk<
  { currentQueries: DBSCurrentQuery[] },
  { engine: string; id: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "service/current-queries",
  async (
    { engine, id, withoutLoading },
    { fulfillWithValue, rejectWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getDBSCurrentQuerriesAsync.pending(requestId, {
          engine,
          id,
          withoutLoading,
        })
      );

      const response = await getDBSCurrentQueriesApi(engine, id);
      const currentQueries = response.data.Result.queries;
      return fulfillWithValue({ currentQueries });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSServiceIntegrationCapabilitiesAsync = createAsyncThunk<
  { capabilities: DBSIntegrationCapability[] },
  { engine: string; clusterId: string },
  IThunkRejectValue
>(
  "service/service-integration-capabilities",
  async ({ engine, clusterId }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getDBSServiceIntegrationCapabilitiesApi(
        engine,
        clusterId
      );
      const capabilities = response.data.Result;
      return fulfillWithValue({ capabilities });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSServiceIntegrationsAsync = createAsyncThunk<
  { integrations: DBSIntegration[] },
  { engine: string; clusterId: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "service/service-integrations",
  async (
    { engine, clusterId, withoutLoading },
    { fulfillWithValue, rejectWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getDBSServiceIntegrationsAsync.pending(requestId, {
          engine,
          clusterId,
          withoutLoading,
        })
      );

      const response = await getDBSServiceIntegrationsApi(engine, clusterId);
      const integrations = response.data.Result;
      return fulfillWithValue({ integrations });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const addDBSServiceIntegrationAsync = createAsyncThunk<
  boolean,
  { engine: string; clusterId: string; data: any },
  IThunkRejectValue
>(
  "service/service-integrations/create",
  async ({ engine, clusterId, data }, { rejectWithValue }) => {
    try {
      await addDBSServiceIntegrationApi(engine, clusterId, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteDBSServiceIntegrationAsync = createAsyncThunk<
  boolean,
  { engine: string; clusterId: string; integrationId: string },
  IThunkRejectValue
>(
  "service/service-integrations/delete",
  async ({ engine, clusterId, integrationId }, { rejectWithValue }) => {
    try {
      await deleteDBSServiceIntegrationApi(engine, clusterId, integrationId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSBackupsAsync = createAsyncThunk<
  { backups: IDBSBackup[] },
  { engine: string; id: string },
  IThunkRejectValue
>(
  "srvice/backups",
  async ({ engine, id }, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await getDBSBackupsApi(engine, id);
      const backups = response.data.Result;
      return fulfillWithValue({ backups });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSAvancedConfigurationCapabilitiesAsync = createAsyncThunk<
  { advancedConfigurationsCapabilities: Array<DBS_ACC> },
  {
    engine: string;
    clusterId: string;
  },
  IThunkRejectValue
>(
  "service/advanced-configurations/capabilities",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { engine, clusterId } = data;
      const response = await etDBSAvancedConfigurationCapabilitiesApi(
        engine,
        clusterId
      );
      const advancedConfigurationsCapabilities = response.data.Result;
      return fulfillWithValue({ advancedConfigurationsCapabilities });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSAvancedConfigurationsAsync = createAsyncThunk<
  { advancedConfigurations: Record<string, string> },
  {
    engine: string;
    clusterId: string;
  },
  IThunkRejectValue
>(
  "service/advanced-configurations",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { engine, clusterId } = data;
      const response = await getDBSAvancedConfigurationsApi(engine, clusterId);
      const advancedConfigurations = response.data.Result || {};
      return fulfillWithValue({ advancedConfigurations });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateDBSAvancedConfigurationsAsync = createAsyncThunk<
  boolean,
  {
    engine: string;
    clusterId: string;
    data: any;
  },
  IThunkRejectValue
>(
  "service/advanced-configurations/update",
  async ({ engine, clusterId, data }, { rejectWithValue }) => {
    try {
      await updateDBSAvancedConfigurationsApi(engine, clusterId, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSPoolsAsync = createAsyncThunk<
  { pools: Array<DBSPool> },
  {
    engine: string;
    clusterId: string;
  },
  IThunkRejectValue
>("service/pools", async (data, { rejectWithValue, fulfillWithValue }) => {
  try {
    const { engine, clusterId } = data;
    const response = await getDBSPoolsApi(engine, clusterId);
    const pools = response.data.Result;
    return fulfillWithValue({ pools });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createDBSPoolAsync = createAsyncThunk<
  boolean,
  { engine: string; clusterId: string; data: any },
  IThunkRejectValue
>(
  "service/pools/create",
  async ({ engine, clusterId, data }, { rejectWithValue }) => {
    try {
      await createDBSPoolApi(engine, clusterId, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateDBSPoolAsync = createAsyncThunk<
  boolean,
  { engine: string; clusterId: string; poolId: string; data: any },
  IThunkRejectValue
>(
  "service/pools/update",
  async ({ engine, clusterId, poolId, data }, { rejectWithValue }) => {
    try {
      await updateDBSPoolApi(engine, clusterId, poolId, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteDBSPoolAsync = createAsyncThunk<
  boolean,
  { engine: string; clusterId: string; poolId: string },
  IThunkRejectValue
>(
  "service/pools/delete",
  async ({ engine, clusterId, poolId }, { rejectWithValue }) => {
    try {
      await deleteDBSPoolApi(engine, clusterId, poolId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSCertificateAsync = createAsyncThunk<
  { certificate: IDBSCertificate },
  { engine: string; id: string },
  IThunkRejectValue
>(
  "service/certificate",
  async ({ engine, id }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDBSCertificateApi(engine, id);
      const certificate = response.data.Result;
      return fulfillWithValue({ certificate });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSNamespacesAsync = createAsyncThunk<
  { namespaces: Array<IDBSNamespaces> },
  { engine: string; id: string },
  IThunkRejectValue
>(
  "service/namespaces",
  async ({ engine, id }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDBSNamespacesApi(engine, id);
      const namespaces = response.data.Result;
      return fulfillWithValue({ namespaces });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createDBSNamespaceAsync = createAsyncThunk<
  { namespace: IDBSNamespaces },
  { engine: string; id: string; data: IDBSCreateUser },
  IThunkRejectValue
>(
  "service/namespace/create",
  async ({ engine, id, data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await createDBSNamespaceApi(engine, id, data);
      const namespace = response.data.Result;
      return fulfillWithValue({ namespace });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateDBSNamespaceAsync = createAsyncThunk<
  boolean,
  {
    engine: string;
    id: string;
    namespaceId: string;
    data: any;
  },
  IThunkRejectValue
>(
  "service/namespace/update",
  async ({ engine, id, namespaceId, data }, { rejectWithValue }) => {
    try {
      await updateBSNamespaceApi(engine, id, namespaceId, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteDBSNmaespaceAsync = createAsyncThunk<
  any,
  { engine: string; id: string; namespaceId: string },
  IThunkRejectValue
>(
  "service/namespace/delete",
  async (
    { engine, id, namespaceId },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await deleteBSNamespaceApi(engine, id, namespaceId);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const DBSRestoreAsync = createAsyncThunk<
  boolean,
  { engine: string; clusterId: string; backupId: string },
  IThunkRejectValue
>(
  "service/backup/restore",
  async ({ engine, clusterId, backupId }, { rejectWithValue }) => {
    try {
      await DBSRestoreApi(engine, clusterId, backupId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const addDBSNodeAsync = createAsyncThunk<
  boolean,
  { engine: string; clusterId: string; data: any },
  IThunkRejectValue
>(
  "service/nodes/add",
  async ({ engine, clusterId, data }, { rejectWithValue }) => {
    try {
      await addDBSNodeApi(engine, clusterId, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteDBSNodeAsync = createAsyncThunk<
  boolean,
  { engine: string; clusterId: string; nodeId: string },
  IThunkRejectValue
>(
  "service/nodes/delete",
  async ({ engine, clusterId, nodeId }, { rejectWithValue }) => {
    try {
      await deleteDBSNodeApi(engine, clusterId, nodeId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDSSReplicationsAsync = createAsyncThunk<
  { replications: Array<ReplicationFlow> },
  {
    engine: string;
    clusterId: string;
  },
  IThunkRejectValue
>(
  "service/replications",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { engine, clusterId } = data;
      const response = await getDSSReplicationsApi(engine, clusterId);
      const replications = response.data.Result;
      return fulfillWithValue({ replications });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteDSSReplicationAsync = createAsyncThunk<
  boolean,
  {
    engine: string;
    clusterId: string;
    replicationId: string;
  },
  IThunkRejectValue
>(
  "service/replications/delete",
  async ({ engine, clusterId, replicationId }, { rejectWithValue }) => {
    try {
      await deleteDSSReplication(engine, clusterId, replicationId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDSSConnectorsAsync = createAsyncThunk<
  { connectors: Array<Connector> },
  {
    engine: string;
    clusterId: string;
  },
  IThunkRejectValue
>("service/connectors", async (data, { rejectWithValue, fulfillWithValue }) => {
  try {
    const { engine, clusterId } = data;
    const response = await getDSSConnectorsApi(engine, clusterId);
    const connectors = response.data.Result;
    return fulfillWithValue({ connectors });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteDSSConnectorAsync = createAsyncThunk<
  boolean,
  {
    engine: string;
    clusterId: string;
    connectorId: string;
  },
  IThunkRejectValue
>(
  "service/connectors/delete",
  async ({ engine, clusterId, connectorId }, { rejectWithValue }) => {
    try {
      await deleteDSSConnector(engine, clusterId, connectorId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDSSACLsAsync = createAsyncThunk<
  { acls: Array<ACL> },
  {
    engine: string;
    clusterId: string;
  },
  IThunkRejectValue
>("service/acls", async (data, { rejectWithValue, fulfillWithValue }) => {
  try {
    const { engine, clusterId } = data;
    const response = await getDSSACLsApi(engine, clusterId);
    const acls = response.data.Result;
    return fulfillWithValue({ acls });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getDSSACLAsync = createAsyncThunk<
  { acl: ACL },
  {
    engine: string;
    clusterId: string;
    aclId: string;
  },
  IThunkRejectValue
>("service/acl", async (data, { rejectWithValue, fulfillWithValue }) => {
  try {
    const { engine, clusterId, aclId } = data;
    const response = await getDSSACLApi(engine, clusterId, aclId);
    const acl = response.data.Result;
    return fulfillWithValue({ acl });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createDSSACLAsync = createAsyncThunk<
  { acl: ACL },
  { engine: string; id: string; data: any },
  IThunkRejectValue
>(
  "service/acls/create",
  async ({ engine, id, data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await createDSSACLApi(engine, id, data);
      const acl = response.data.Result;
      return fulfillWithValue({ acl });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteDSSACLAsync = createAsyncThunk<
  boolean,
  {
    engine: string;
    clusterId: string;
    aclId: string;
  },
  IThunkRejectValue
>(
  "service/acls/delete",
  async ({ engine, clusterId, aclId }, { rejectWithValue }) => {
    try {
      await deleteDSSACLApi(engine, clusterId, aclId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDSSTopicsAsync = createAsyncThunk<
  { topics: Topic[] },
  {
    engine: string;
    clusterId: string;
  },
  IThunkRejectValue
>("service/topics", async (data, { rejectWithValue, fulfillWithValue }) => {
  try {
    const { engine, clusterId } = data;
    const response = await getDSSTopicsApi(engine, clusterId);
    const topics = response.data.Result;
    return fulfillWithValue({ topics });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createDSSTopicAsync = createAsyncThunk<
  { topic: any },
  { engine: string; id: string; data: any },
  IThunkRejectValue
>(
  "service/topics/create",
  async ({ engine, id, data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await createDSSTopicApi(engine, id, data);
      const topic = response.data.Result;
      return fulfillWithValue({ topic });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteDSSTopicAsync = createAsyncThunk<
  boolean,
  {
    engine: string;
    clusterId: string;
    topicId: string;
  },
  IThunkRejectValue
>(
  "service/topics/delete",
  async ({ engine, clusterId, topicId }, { rejectWithValue }) => {
    try {
      await deleteDSSTopicsApi(engine, clusterId, topicId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    handleClearServiceSlice: (state) => {
      state.service = null;
    },
    handleShowIPsEditorModal: (
      state,
      action: PayloadAction<{ ip?: IpRestriction }>
    ) => {
      state.showEditorModal = true;
      if (action.payload.ip) {
        state.selectedIP = action.payload.ip;
      }
    },
    handleHideIPsEditorModal: (state) => {
      state.showEditorModal = false;
      state.selectedIP = null;
    },
    handleDBSDatabaseEditor: (state, action: PayloadAction<boolean>) => {
      state.databaseShowEditor = action.payload;
    },
    handleDBSIntegrationEditor: (state, action: PayloadAction<boolean>) => {
      state.integrationShowEditor = action.payload;
    },
    handleDBSPoolEditor: (
      state,
      action: PayloadAction<{ status: boolean; pool?: DBSPool | null }>
    ) => {
      state.poolsShowEditor = action.payload.status;
      state.selectedPool = action.payload.pool || null;
    },
    handleDBSPoolInfo: (
      state,
      action: PayloadAction<{ status: boolean; pool?: DBSPool | null }>
    ) => {
      state.poolsShowInfo = action.payload.status;
      state.selectedPool = action.payload.pool || null;
    },

    handleShowNamespaceEditorModal: (
      state,
      action: PayloadAction<{ namespace?: IDBSNamespaces }>
    ) => {
      state.namespaceShowEditor = true;
      if (action.payload.namespace) {
        state.selectedNamespace = action.payload.namespace;
      }
    },
    handleHideNamespaceEditorModal: (state) => {
      state.namespaceShowEditor = false;
      state.selectedNamespace = null;
    },
    handleDBSHideUserDetailsModal: (state) => {
      state.showUserDetailsModal = false;
    },
    handleDBSShowUserDetailsModal: (state, action: PayloadAction<IDBSUser>) => {
      state.showUserDetailsModal = true;
      state.user = action.payload;
    },

    handleToggleDBSUserResetpasswordModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.showUserResetPasswordModal = action.payload || false;
    },
    handleToggleDSSAclModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.showAclEditorModal = action.payload || false;
    },
    handleToggleDSSTopicModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.showTopicEditorModal = action.payload || false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDatabaseServiceAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getDatabaseServiceAsync.fulfilled, (state, action) => {
        const { service } = action.payload;
        state.service = service;
        state.loading = false;
      })
      .addCase(getDatabaseServiceAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getDBSMaintenanceAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDBSMaintenanceAsync.fulfilled, (state, action) => {
        const { maintenance } = action.payload;
        state.maintenance = maintenance;
        state.loading = false;
      })
      .addCase(getDBSMaintenanceAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    //users
    builder
      .addCase(getDBSUsersAsync.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(getDBSUsersAsync.fulfilled, (state, action) => {
        const { users } = action.payload;
        state.users = users;
        state.usersLoading = false;
      })
      .addCase(getDBSUsersAsync.rejected, (state, { payload }) => {
        state.usersLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(resetDBSUsersAsync.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(resetDBSUsersAsync.fulfilled, (state, action) => {
        const { user } = action.payload;
        state.user = user;
        state.userLoading = false;
      })
      .addCase(resetDBSUsersAsync.rejected, (state, { payload }) => {
        state.userLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(createDBSUserAsync.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(createDBSUserAsync.fulfilled, (state, action) => {
        // const { user } = action.payload;
        // state.user = user;
        state.userLoading = false;
      })
      .addCase(createDBSUserAsync.rejected, (state, { payload }) => {
        state.userLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(updateDBSUserAsync.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(updateDBSUserAsync.fulfilled, (state, action) => {
        // const { user } = action.payload;
        // state.user = user;
        state.userLoading = false;
      })
      .addCase(updateDBSUserAsync.rejected, (state, { payload }) => {
        state.userLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(deleteDBSUserAsync.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(deleteDBSUserAsync.fulfilled, (state, action) => {
        // const { user } = action.payload;
        // state.user = user;
        state.userLoading = false;
      })
      .addCase(deleteDBSUserAsync.rejected, (state, { payload }) => {
        state.userLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //logs
    builder
      .addCase(getDBSLogsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.logsLoading = true;
      })
      .addCase(getDBSLogsAsync.fulfilled, (state, { payload: { logs } }) => {
        state.logs = logs;
        state.logsLoading = false;
      })
      .addCase(getDBSLogsAsync.rejected, (state, { payload }) => {
        state.logsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //metrics
    builder
      .addCase(getDBSMetricValuesAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.metricsLoading = true;
      })
      .addCase(
        getDBSMetricValuesAsync.fulfilled,
        (state, { payload: { metricValues } }) => {
          state.metrics = metricValues;
          state.metricsLoading = false;
        }
      )
      .addCase(getDBSMetricValuesAsync.rejected, (state, { payload }) => {
        state.metricsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //databases
    builder
      .addCase(getDBSDatabasesAsync.pending, (state) => {
        state.databasesLoading = true;
      })
      .addCase(
        getDBSDatabasesAsync.fulfilled,
        (state, { payload: { databases } }) => {
          state.databases = databases;
          state.databasesLoading = false;
        }
      )
      .addCase(getDBSDatabasesAsync.rejected, (state, { payload }) => {
        state.databasesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createDBSDatabasesAsync.pending, (state) => {
        state.databasesActionLoading = true;
      })
      .addCase(createDBSDatabasesAsync.fulfilled, (state) => {
        state.databasesActionLoading = false;
      })
      .addCase(createDBSDatabasesAsync.rejected, (state, { payload }) => {
        state.databasesActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteDBSDatabasesAsync.pending, (state) => {
        state.databasesActionLoading = true;
      })
      .addCase(deleteDBSDatabasesAsync.fulfilled, (state) => {
        state.databasesActionLoading = false;
      })
      .addCase(deleteDBSDatabasesAsync.rejected, (state, { payload }) => {
        state.databasesActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    //querrStatistics
    builder
      .addCase(getDBSQuerrStatisticsAsync.pending, (state) => {
        state.queryStatistictsLoading = true;
      })
      .addCase(getDBSQuerrStatisticsAsync.fulfilled, (state) => {
        state.queryStatistictsLoading = false;
      })
      .addCase(getDBSQuerrStatisticsAsync.rejected, (state, { payload }) => {
        state.queryStatistictsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(resetDBSQuerrStatisticsAsync.pending, (state) => {
        state.queryStatistictsLoading = true;
      })
      .addCase(resetDBSQuerrStatisticsAsync.fulfilled, (state) => {
        state.queryStatistictsLoading = false;
      })
      .addCase(resetDBSQuerrStatisticsAsync.rejected, (state, { payload }) => {
        state.queryStatistictsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //current queries
    builder
      .addCase(getDBSCurrentQuerriesAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.currentQueriesLoading = true;
      })
      .addCase(
        getDBSCurrentQuerriesAsync.fulfilled,
        (state, { payload: { currentQueries } }) => {
          state.currentQueries = currentQueries;
          state.currentQueriesLoading = false;
        }
      )
      .addCase(getDBSCurrentQuerriesAsync.rejected, (state, { payload }) => {
        state.currentQueriesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //service integrations
    builder
      .addCase(getDBSServiceIntegrationCapabilitiesAsync.pending, (state) => {
        state.integrationsCapabilitiesLoading = true;
      })
      .addCase(
        getDBSServiceIntegrationCapabilitiesAsync.fulfilled,
        (state, action) => {
          state.integrationsCapabilitiesLoading = false;
          state.integrationsCapabilities = action.payload.capabilities;
        }
      )
      .addCase(
        getDBSServiceIntegrationCapabilitiesAsync.rejected,
        (state, { payload }) => {
          state.integrationsCapabilitiesLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(getDBSServiceIntegrationsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.integrationsLoading = true;
      })
      .addCase(
        getDBSServiceIntegrationsAsync.fulfilled,
        (state, { payload: { integrations } }) => {
          state.integrations = integrations;
          state.integrationsLoading = false;
        }
      )
      .addCase(
        getDBSServiceIntegrationsAsync.rejected,
        (state, { payload }) => {
          state.integrationsLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(addDBSServiceIntegrationAsync.pending, (state, action) => {
        state.integrationsActionLoading = true;
      })
      .addCase(addDBSServiceIntegrationAsync.fulfilled, (state) => {
        state.integrationsActionLoading = false;
      })
      .addCase(addDBSServiceIntegrationAsync.rejected, (state, { payload }) => {
        state.integrationsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteDBSServiceIntegrationAsync.pending, (state, action) => {
        state.integrationsActionLoading = true;
      })
      .addCase(deleteDBSServiceIntegrationAsync.fulfilled, (state) => {
        state.integrationsActionLoading = false;
      })
      .addCase(
        deleteDBSServiceIntegrationAsync.rejected,
        (state, { payload }) => {
          state.integrationsActionLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    //backups
    builder
      .addCase(getDBSBackupsAsync.pending, (state) => {
        state.backupsloading = true;
      })
      .addCase(
        getDBSBackupsAsync.fulfilled,
        (state, { payload: { backups } }) => {
          state.backups = backups;
          state.backupsloading = false;
        }
      )
      .addCase(getDBSBackupsAsync.rejected, (state, { payload }) => {
        state.backupsloading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //advanced configuration
    builder
      .addCase(getDBSAvancedConfigurationCapabilitiesAsync.pending, (state) => {
        state.advancedConfigurationsCapabilitiesLoading = true;
      })
      .addCase(
        getDBSAvancedConfigurationCapabilitiesAsync.fulfilled,
        (state, { payload: { advancedConfigurationsCapabilities } }) => {
          state.advancedConfigurationsCapabilities =
            advancedConfigurationsCapabilities;
          state.advancedConfigurationsCapabilitiesLoading = false;
        }
      )
      .addCase(
        getDBSAvancedConfigurationCapabilitiesAsync.rejected,
        (state, { payload }) => {
          state.advancedConfigurationsCapabilitiesLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(getDBSAvancedConfigurationsAsync.pending, (state) => {
        state.advancedConfigurationsLoading = true;
      })
      .addCase(
        getDBSAvancedConfigurationsAsync.fulfilled,
        (state, { payload: { advancedConfigurations } }) => {
          state.advancedConfigurations = advancedConfigurations;
          state.advancedConfigurationsLoading = false;
        }
      )
      .addCase(
        getDBSAvancedConfigurationsAsync.rejected,
        (state, { payload }) => {
          state.advancedConfigurationsLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(updateDBSAvancedConfigurationsAsync.pending, (state) => {
        state.advancedConfigurationsActionLoading = true;
      })
      .addCase(updateDBSAvancedConfigurationsAsync.fulfilled, (state) => {
        state.advancedConfigurationsActionLoading = false;
      })
      .addCase(
        updateDBSAvancedConfigurationsAsync.rejected,
        (state, { payload }) => {
          state.advancedConfigurationsActionLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    //pools
    builder
      .addCase(getDBSPoolsAsync.pending, (state) => {
        state.poolsLoading = true;
      })
      .addCase(getDBSPoolsAsync.fulfilled, (state, { payload: { pools } }) => {
        state.pools = pools;
        state.poolsLoading = false;
      })
      .addCase(getDBSPoolsAsync.rejected, (state, { payload }) => {
        state.poolsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createDBSPoolAsync.pending, (state) => {
        state.poolsActionLoading = true;
      })
      .addCase(createDBSPoolAsync.fulfilled, (state) => {
        state.poolsActionLoading = false;
      })
      .addCase(createDBSPoolAsync.rejected, (state, { payload }) => {
        state.poolsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateDBSPoolAsync.pending, (state) => {
        state.poolsActionLoading = true;
      })
      .addCase(updateDBSPoolAsync.fulfilled, (state) => {
        state.poolsActionLoading = false;
      })
      .addCase(updateDBSPoolAsync.rejected, (state, { payload }) => {
        state.poolsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteDBSPoolAsync.pending, (state) => {
        state.poolsActionLoading = true;
      })
      .addCase(deleteDBSPoolAsync.fulfilled, (state) => {
        state.poolsActionLoading = false;
      })
      .addCase(deleteDBSPoolAsync.rejected, (state, { payload }) => {
        state.poolsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    //certificate
    builder
      .addCase(getDBSCertificateAsync.pending, (state) => {
        state.certificateLoading = true;
      })
      .addCase(
        getDBSCertificateAsync.fulfilled,
        (state, { payload: { certificate } }) => {
          state.certificate = certificate;
          state.certificateLoading = false;
        }
      )
      .addCase(getDBSCertificateAsync.rejected, (state, { payload }) => {
        state.certificateLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    //namespace
    builder
      .addCase(getDBSNamespacesAsync.pending, (state) => {
        state.namespacesLoading = true;
      })
      .addCase(
        getDBSNamespacesAsync.fulfilled,
        (state, { payload: { namespaces } }) => {
          state.namespaces = namespaces;
          state.namespacesLoading = false;
        }
      )
      .addCase(getDBSNamespacesAsync.rejected, (state, { payload }) => {
        state.namespacesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(createDBSNamespaceAsync.pending, (state) => {
        state.namespacesLoading = true;
      })
      .addCase(createDBSNamespaceAsync.fulfilled, (state, action) => {
        state.namespacesLoading = false;
      })
      .addCase(createDBSNamespaceAsync.rejected, (state, { payload }) => {
        state.namespacesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(updateDBSNamespaceAsync.pending, (state) => {
        state.namespacesLoading = true;
      })
      .addCase(updateDBSNamespaceAsync.fulfilled, (state) => {
        state.namespacesLoading = false;
      })
      .addCase(updateDBSNamespaceAsync.rejected, (state, { payload }) => {
        state.namespacesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(deleteDBSNmaespaceAsync.pending, (state) => {
        state.namespacesLoading = true;
      })
      .addCase(deleteDBSNmaespaceAsync.fulfilled, (state, action) => {
        state.namespacesLoading = false;
        state.selectedNamespace = null;
      })
      .addCase(deleteDBSNmaespaceAsync.rejected, (state, { payload }) => {
        state.namespacesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(DBSRestoreAsync.pending, (state) => {
        state.backupsloading = true;
      })
      .addCase(DBSRestoreAsync.fulfilled, (state, action) => {
        state.backupsloading = false;
      })
      .addCase(DBSRestoreAsync.rejected, (state, { payload }) => {
        state.backupsloading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(addDBSNodeAsync.pending, (state) => {
        state.nodesActionLoading = true;
      })
      .addCase(addDBSNodeAsync.fulfilled, (state) => {
        state.nodesActionLoading = false;
      })
      .addCase(addDBSNodeAsync.rejected, (state, { payload }) => {
        state.nodesActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteDBSNodeAsync.pending, (state) => {
        state.nodesActionLoading = true;
      })
      .addCase(deleteDBSNodeAsync.fulfilled, (state) => {
        state.nodesActionLoading = false;
      })
      .addCase(deleteDBSNodeAsync.rejected, (state, { payload }) => {
        state.nodesActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getDSSReplicationsAsync.pending, (state) => {
        state.replicationsLoading = true;
      })
      .addCase(
        getDSSReplicationsAsync.fulfilled,
        (state, { payload: { replications } }) => {
          state.replicationsLoading = false;
          state.replications = replications;
        }
      )
      .addCase(getDSSReplicationsAsync.rejected, (state, { payload }) => {
        state.replicationsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteDSSReplicationAsync.pending, (state) => {
        state.replicationsActionLoading = true;
      })
      .addCase(deleteDSSReplicationAsync.fulfilled, (state) => {
        state.replicationsActionLoading = false;
      })
      .addCase(deleteDSSReplicationAsync.rejected, (state, { payload }) => {
        state.replicationsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getDSSConnectorsAsync.pending, (state) => {
        state.connectorsLoading = true;
      })
      .addCase(
        getDSSConnectorsAsync.fulfilled,
        (state, { payload: { connectors } }) => {
          state.connectorsLoading = false;
          state.connectors = connectors;
        }
      )
      .addCase(getDSSConnectorsAsync.rejected, (state, { payload }) => {
        state.connectorsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteDSSConnectorAsync.pending, (state) => {
        state.connectorsActionLoading = true;
      })
      .addCase(deleteDSSConnectorAsync.fulfilled, (state) => {
        state.connectorsActionLoading = false;
      })
      .addCase(deleteDSSConnectorAsync.rejected, (state, { payload }) => {
        state.connectorsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getDSSACLsAsync.pending, (state) => {
        state.aclsLoading = true;
      })
      .addCase(getDSSACLsAsync.fulfilled, (state, { payload: { acls } }) => {
        state.aclsLoading = false;
        state.acls = acls;
      })
      .addCase(getDSSACLsAsync.rejected, (state, { payload }) => {
        state.aclsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getDSSACLAsync.pending, (state) => {
        state.aclLoading = true;
      })
      .addCase(getDSSACLAsync.fulfilled, (state, { payload: { acl } }) => {
        state.aclLoading = false;
        state.acl = acl;
      })
      .addCase(getDSSACLAsync.rejected, (state, { payload }) => {
        state.aclLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(createDSSACLAsync.pending, (state) => {
        state.aclsLoading = true;
      })
      .addCase(createDSSACLAsync.fulfilled, (state, action) => {
        state.aclsLoading = false;
      })
      .addCase(createDSSACLAsync.rejected, (state, { payload }) => {
        state.aclsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(deleteDSSACLAsync.pending, (state, action) => {
        state.aclsActionLoading = true;
      })
      .addCase(deleteDSSACLAsync.fulfilled, (state) => {
        state.aclsActionLoading = false;
      })
      .addCase(deleteDSSACLAsync.rejected, (state, { payload }) => {
        state.aclsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getDSSTopicsAsync.pending, (state) => {
        state.topicsLoading = true;
      })
      .addCase(
        getDSSTopicsAsync.fulfilled,
        (state, { payload: { topics } }) => {
          state.topicsLoading = false;
          state.topics = topics;
        }
      )
      .addCase(getDSSTopicsAsync.rejected, (state, { payload }) => {
        state.topicsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(createDSSTopicAsync.pending, (state) => {
        state.topicsActionLoading = true;
      })
      .addCase(createDSSTopicAsync.fulfilled, (state, action) => {
        state.topicsActionLoading = false;
      })
      .addCase(createDSSTopicAsync.rejected, (state, { payload }) => {
        state.topicsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(deleteDSSTopicAsync.pending, (state, action) => {
        state.topicsActionLoading = true;
      })
      .addCase(deleteDSSTopicAsync.fulfilled, (state) => {
        state.topicsActionLoading = false;
      })
      .addCase(deleteDSSTopicAsync.rejected, (state, { payload }) => {
        state.topicsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectDatabaseService = (state: RootState) =>
  state.databaseService.service;
export const selectDatabaseServiceLoading = (state: RootState) =>
  state.databaseService.loading;
export const selectDatabaseServiceEditorIsOpen = (state: RootState) =>
  state.databaseService.showEditorModal;

//users
export const selectDatabaseServiceUsers = (state: RootState) =>
  state.databaseService.users;
export const selectDatabaseServiceUsersLoading = (state: RootState) =>
  state.databaseService.usersLoading;
export const selectDatabaseServiceUser = (state: RootState) =>
  state.databaseService.user;
export const selectDatabaseServiceUserLoading = (state: RootState) =>
  state.databaseService.userLoading;
export const selectDatabaseUserDetails = (state: RootState) =>
  state.databaseService.showUserDetailsModal;
export const selectDatabaseUserResetpassword = (state: RootState) =>
  state.databaseService.showUserResetPasswordModal;

//IP
export const selectDelectedDatabaseServiceIP = (state: RootState) =>
  state.databaseService.selectedIP;
export const selectDatabaseServiceActionLoading = (state: RootState) =>
  state.databaseService.actionLoading;

//logs
export const selectDBSLogs = (state: RootState) => state.databaseService.logs;
export const selectDBSLogsLoading = (state: RootState) =>
  state.databaseService.logsLoading;

//metrics
export const selectDBSMetrics = (state: RootState) =>
  state.databaseService.metrics;
export const selectDBSMetricsLoading = (state: RootState) =>
  state.databaseService.metricsLoading;

//databases
export const selectDBSDatabases = (state: RootState) =>
  state.databaseService.databases;
export const selectDBSDatabasesLoading = (state: RootState) =>
  state.databaseService.databasesLoading;
export const selectDBSDatabaseShowEditor = (state: RootState) =>
  state.databaseService.databaseShowEditor;
export const selectDBSDatabasesActionLoading = (state: RootState) =>
  state.databaseService.databasesActionLoading;

//querrStatistics
export const selectDBSQuerrStatistics = (state: RootState) =>
  state.databaseService.queryStatisticts;
export const selectDBSQuerrStatisticsLoading = (state: RootState) =>
  state.databaseService.queryStatistictsLoading;

//querrStatistics
export const selectDBSCurrentQueries = (state: RootState) =>
  state.databaseService.currentQueries;
export const selectDBSCurrentQueriesLoading = (state: RootState) =>
  state.databaseService.currentQueriesLoading;

//querrStatistics
export const selectDBSIntegrations = (state: RootState) =>
  state.databaseService.integrations;
export const selectDBSIntegrationsLoading = (state: RootState) =>
  state.databaseService.integrationsLoading;
export const selectDBSIntegrationsActionLoading = (state: RootState) =>
  state.databaseService.integrationsActionLoading;
export const selectDBSIntegrationsShowEditor = (state: RootState) =>
  state.databaseService.integrationShowEditor;
export const selectDBSIntegrationCapabilities = (state: RootState) =>
  state.databaseService.integrationsCapabilities;
export const selectDBSIntegrationCapabilitiesLoading = (state: RootState) =>
  state.databaseService.integrationsCapabilitiesLoading;

//advances configurations
export const selectDBSAdvancedConfigurationsCapabilities = (state: RootState) =>
  state.databaseService.advancedConfigurationsCapabilities;
export const selectDBSAdvancedConfigurationsCapabilitiesLoading = (
  state: RootState
) => state.databaseService.advancedConfigurationsCapabilitiesLoading;
export const selectDBSAdvancedConfigurations = (state: RootState) =>
  state.databaseService.advancedConfigurations;
export const selectDBSAdvancedConfigurationsLoading = (state: RootState) =>
  state.databaseService.advancedConfigurationsLoading;
export const selectDBSAdvancedConfigurationsActionLoading = (
  state: RootState
) => state.databaseService.advancedConfigurationsActionLoading;

//backups
export const selectDBSBackups = (state: RootState) =>
  state.databaseService.backups;
export const selectDBSBackupsLoading = (state: RootState) =>
  state.databaseService.backupsloading;

//pools
export const selectDBSPools = (state: RootState) => state.databaseService.pools;
export const selectDBSPoolsLoading = (state: RootState) =>
  state.databaseService.poolsLoading;
export const selectDBSPoolsActionLoading = (state: RootState) =>
  state.databaseService.poolsActionLoading;
export const selectDBSPoolsShowEditor = (state: RootState) =>
  state.databaseService.poolsShowEditor;
export const selectDBSSelectedPool = (state: RootState) =>
  state.databaseService.selectedPool;
export const selectDBSPoolsShowInfo = (state: RootState) =>
  state.databaseService.poolsShowInfo;

//certificate
export const selectCertificate = (state: RootState) =>
  state.databaseService.certificate;
export const selectCertificateLoading = (state: RootState) =>
  state.databaseService.certificateLoading;

//certificate
export const selectNamespaces = (state: RootState) =>
  state.databaseService.namespaces;
export const selectNamespacesLoading = (state: RootState) =>
  state.databaseService.namespacesLoading;
export const selectSelectedNamespace = (state: RootState) =>
  state.databaseService.selectedNamespace;
export const selectShowNamespaceEditor = (state: RootState) =>
  state.databaseService.namespaceShowEditor;

//nodes
export const selectDBSNodesActionLoading = (state: RootState) =>
  state.databaseService.nodesActionLoading;

//replications
export const selectDSSReplications = (state: RootState) =>
  state.databaseService.replications;
export const selectDSSReplicationsLoading = (state: RootState) =>
  state.databaseService.replicationsLoading;
export const selectDSSReplicationsActionLoading = (state: RootState) =>
  state.databaseService.replicationsActionLoading;

//connectors
export const selectDSSConnectors = (state: RootState) =>
  state.databaseService.connectors;
export const selectDSSConnectorsLoading = (state: RootState) =>
  state.databaseService.connectorsLoading;
export const selectDSSConnectorsActionLoading = (state: RootState) =>
  state.databaseService.connectorsActionLoading;

//acls
export const selectDSSAcls = (state: RootState) => state.databaseService.acls;
export const selectDSSAclsLoading = (state: RootState) =>
  state.databaseService.aclsLoading;
export const selectDSSAclsActionLoading = (state: RootState) =>
  state.databaseService.aclsActionLoading;
export const selectDSSShowAclEditorModal = (state: RootState) =>
  state.databaseService.showAclEditorModal;

export const selectDSSAcl = (state: RootState) => state.databaseService.acl;
export const selectDSSAclLoading = (state: RootState) =>
  state.databaseService.aclLoading;

//topics
export const selectDSStopics = (state: RootState) =>
  state.databaseService.topics;
export const selectDSSTopicsLoading = (state: RootState) =>
  state.databaseService.topicsLoading;
export const selectDSSTopicsActionLoading = (state: RootState) =>
  state.databaseService.topicsActionLoading;
export const selectDSSShowTopicEditorModal = (state: RootState) =>
  state.databaseService.showTopicEditorModal;

export const {
  handleDBSShowUserDetailsModal,
  handleDBSHideUserDetailsModal,
  handleClearServiceSlice,
  handleShowIPsEditorModal,
  handleHideIPsEditorModal,
  handleDBSDatabaseEditor,
  handleDBSIntegrationEditor,
  handleDBSPoolEditor,
  handleDBSPoolInfo,
  handleShowNamespaceEditorModal,
  handleHideNamespaceEditorModal,
  handleToggleDBSUserResetpasswordModal,
  handleToggleDSSAclModal,
  handleToggleDSSTopicModal,
} = serviceSlice.actions;
export default serviceSlice.reducer;
