import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Input,
  Modal,
  Select,
  Textarea,
  Typography,
} from "djuno-design";
import { useCallback, useEffect, useState } from "react";
import {
  createDSSACLAsync,
  getDSSACLsAsync,
  handleToggleDSSAclModal,
  selectDatabaseService,
  selectDSSAcl,
  selectDSSAclsLoading,
  selectDSSShowAclEditorModal,
} from "../../../store/database/serviceSlice";
import { DataStreamingACLCreateSchema } from "../../../utils/validations";

const aclPermissionOptions = [
  { label: "admin", value: "admin" },
  { label: "read", value: "read" },
  { label: "write", value: "write" },
  { label: "readwrite", value: "readwrite" },
];

export const ACLCreateModal: React.FC = () => {
  const [permission, setPermission] = useState<string>();
  const isOpen = useAppSelector(selectDSSShowAclEditorModal);
  const service = useAppSelector(selectDatabaseService);
  const aclActionLoading = useAppSelector(selectDSSAclsLoading);
  const acl = useAppSelector(selectDSSAcl);
  const dispatch = useAppDispatch();

  const methods = useForm({
    resolver: yupResolver(DataStreamingACLCreateSchema),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    control,
  } = methods;

  const handleSubmitForm = (data: any) => {
    if (!aclActionLoading) {
      const formData = {
        topic: data.topic,
        username: data.username,
        permission: permission,
      };
      service &&
        dispatch(
          createDSSACLAsync({
            engine: service?.engine,
            id: service?.id,
            data: formData,
          })
        ).then((action) => {
          if (action.type === "service/acls/create/fulfilled") {
            dispatch(
              getDSSACLsAsync({
                engine: service?.engine,
                clusterId: service?.id,
              })
            );
            handleOnClose();
          }
        });
    }
  };

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleToggleDSSAclModal(false));
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  useEffect(() => {
    if (isOpen) {
      setValue("username", "");
      setValue("topic", "");
      setPermission("");
    }
  }, [isOpen, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="!max-w-xl overflow-hidden"
      title={"Add an access"}
    >
      <form className="mt-5" onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex flex-col gap-3">
          <Input
            label="Username"
            {...register("username")}
            error={errors.username?.message}
          />
          <Input
            label="Topic"
            {...register("topic")}
            error={errors.topic?.message}
          />
          <Select
            label="Permission"
            value={permission}
            onChange={(v) => setPermission(v)}
            options={
              aclPermissionOptions.map((s) => ({
                label: s.label,
                value: s.value,
              })) || []
            }
          />

          <div className="flex justify-end gap-2">
            <Button uiType="simple" onClick={handleOnClose}>
              Cancel
            </Button>
            <Button uiType="primary" type="submit" loading={aclActionLoading}>
              Add an access
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
