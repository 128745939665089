import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { ReactComponent as InfoIcon } from "./../../../assets/icons/eye.svg";
import { useNavigate } from "react-router-dom";
import {
  DatabaseServiceGeneralInformationUrl,
  DatabaseUsersCreateUrl,
  DatabaseUsersEditUrl,
} from "../../../utils/urls";
import QuestionModal, {
  DeleteModal,
} from "../../../components/modals/QuestionModal";
import { useSearch } from "../../../providers/SearchProvider";
import {
  getDatabaseServicesAsync,
  selectDatabaseServicesLoading,
} from "../../../store/database/servicesSlice";
import { formatTimestamp } from "../../../utils/date";
import {
  deleteDBSUserAsync,
  getDBSUsersAsync,
  getDatabaseServiceAsync,
  handleDBSShowUserDetailsModal,
  handleToggleDBSUserResetpasswordModal,
  resetDBSUsersAsync,
  selectDatabaseService,
  selectDatabaseServiceUserLoading,
  selectDatabaseServiceUsers,
  selectDatabaseServiceUsersLoading,
} from "../../../store/database/serviceSlice";
import { DBSCategory, IDBSUser } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import UserDetailsModal from "./UserDetailsModal";
import {
  Button,
  Dropdown,
  EmptyState,
  SimpleTable,
  Tag,
  Typography,
} from "djuno-design";
import HighlighterText from "../../general/HighlighterText";
import ResetPasswordModal from "./ResetPasswordModal";
import { ReactComponent as PenIcon } from "./../../../assets/icons/pencil-square.svg";
import { setSurveyQuestionsApi } from "../../../apis/surveyAPI";

const UserTab: React.FC<{ section: DBSCategory }> = () => {
  const [DBSUserId, setDBSUserId] = useState<string | null>(null);
  const [deleteUser, setDeleteUser] = useState<IDBSUser | null>(null);
  const [filteredUsers, setFilteredUsers] = useState<IDBSUser[]>([]);
  const [resetPassword, setResetPassword] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<IDBSUser | null>(null);

  const service = useAppSelector(selectDatabaseService);
  const users = useAppSelector(selectDatabaseServiceUsers);
  const usersLoading = useAppSelector(selectDatabaseServiceUsersLoading);
  const userLoading = useAppSelector(selectDatabaseServiceUserLoading);
  const loading = useAppSelector(selectDatabaseServicesLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().users;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(getDBSUsersAsync({ id: service.id, engine: service.engine }));
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    const lookedUpFiles = users?.filter((user) =>
      user.username?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredUsers(lookedUpFiles);
  }, [dispatch, searchValue, users]);

  const isAnyUserDeleting = useMemo(() => {
    return users.some((user) => user.status === "DELETING");
  }, [users]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center justify-between">
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Typography.Title level={5} className="!mb-0">
                Users & Roles
              </Typography.Title>
            </div>
            <div className="flex items-center gap-2">
              <Button
                uiType="light"
                onClick={() => {
                  if (service)
                    dispatch(
                      getDBSUsersAsync({
                        id: service.id,
                        engine: service.engine,
                      })
                    );
                }}
                disabled={!service}
                className="group"
                tooltip={{ content: "Refresh" }}
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
              <Button
                uiType="primary"
                onClick={() => navigate(DatabaseUsersCreateUrl(service.id))}
                disabled={isAnyUserDeleting}
                className="group"
              >
                Add user
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </div>
          <div className="mt-5 w-full">
            <SimpleTable
              loading={usersLoading}
              containerClassName="min-h-[240px]"
            >
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Username" />
                  {service.engine !== "mysql" &&
                    service.engine !== "m3db" &&
                    service.engine !== "redis" &&
                    service.engine !== "cassandra" &&
                    service.engine !== "kafka" &&
                    service.engine !== "kafkaConnect" && (
                      <SimpleTable.TH lable="Roles" />
                    )}

                  {service.engine === "m3db" && (
                    <SimpleTable.TH lable="Group" />
                  )}
                  {service.engine === "redis" && (
                    <>
                      <SimpleTable.TH lable="Keys" />
                      <SimpleTable.TH lable="	Categories" />
                      <SimpleTable.TH lable="	Commands" />
                      <SimpleTable.TH lable="		Channels" />
                    </>
                  )}
                  <SimpleTable.TH lable="Creation date" />
                  <SimpleTable.TH lable="Status" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>

              <SimpleTable.Body>
                {filteredUsers.map((user, i) => (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD>
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <HighlighterText
                          searchValue={searchValue}
                          textToHighlight={user.username}
                          className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                        />
                      </div>
                    </SimpleTable.TD>

                    {user.roles && (
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {user.roles.map((r) => (
                            <div>{r}</div>
                          ))}
                        </Typography.Text>
                      </SimpleTable.TD>
                    )}

                    {service.engine === "m3db" && (
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {user.group}
                        </Typography.Text>
                      </SimpleTable.TD>
                    )}
                    {service.engine === "redis" && (
                      <>
                        <SimpleTable.TD>
                          {user.keys?.map((key) => (
                            <Tag
                              color="processing"
                              className="!text-xs md:!text-sm !mr-1"
                            >
                              {key}
                            </Tag>
                          ))}
                        </SimpleTable.TD>
                        <SimpleTable.TD>
                          <Typography.Text className="!text-xs md:!text-sm">
                            {user.categories?.map((category) => (
                              <Tag
                                color="processing"
                                className="!text-xs md:!text-sm !mr-1"
                              >
                                {category}
                              </Tag>
                            ))}
                          </Typography.Text>
                        </SimpleTable.TD>
                        <SimpleTable.TD>
                          <Typography.Text className="!text-xs md:!text-sm">
                            {user.commands?.map((command) => (
                              <Tag
                                color="processing"
                                className="!text-xs md:!text-sm !mr-1"
                              >
                                {command}
                              </Tag>
                            ))}
                          </Typography.Text>
                        </SimpleTable.TD>
                        <SimpleTable.TD>
                          <Typography.Text className="!text-xs md:!text-sm">
                            {user.channels?.map((channel) => (
                              <Tag
                                color="processing"
                                className="!text-xs md:!text-sm !mr-1"
                              >
                                {channel}
                              </Tag>
                            ))}
                          </Typography.Text>
                        </SimpleTable.TD>
                      </>
                    )}
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {
                          formatTimestamp(user.createdAt, "MMM DD hh:mm:ss ")
                            .datetime
                        }
                      </Typography.Text>
                    </SimpleTable.TD>

                    <SimpleTable.TD>
                      <Tag
                        className="text-xs"
                        color={user.status === "READY" ? "success" : "warning"}
                      >
                        {user.status}
                      </Tag>
                    </SimpleTable.TD>

                    <SimpleTable.TD className="w-20 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-end gap-1">
                        <div className="w-8">
                          <Dropdown
                            anchor="bottom end"
                            itemsClassName="!w-48"
                            menu={[
                              ...(service.engine === "redis"
                                ? [
                                    {
                                      key: "details",
                                      label: (
                                        <div className="flex items-center gap-1">
                                          <InfoIcon className="w-4" />
                                          View Details
                                        </div>
                                      ),
                                      onClick: (_: any, close: any) => {
                                        close();
                                        dispatch(
                                          handleDBSShowUserDetailsModal(user)
                                        );
                                      },
                                      disabled:
                                        loading ||
                                        isAnyUserDeleting ||
                                        user.status === "PENDING",
                                    },
                                  ]
                                : []),
                              ...(service.engine === "mongodb" ||
                              service.engine === "postgresql" ||
                              service.engine === "m3db" ||
                              service.engine === "redis"
                                ? [
                                    {
                                      key: "edit",
                                      label: (
                                        <div className="flex items-center gap-1">
                                          <PenIcon className="w-4 " />
                                          Edit
                                        </div>
                                      ),
                                      onClick: (_: any, close: any) => {
                                        close();
                                        navigate(
                                          DatabaseUsersEditUrl(
                                            service.id,
                                            user.id
                                          )
                                        );
                                      },
                                      disabled:
                                        loading ||
                                        isAnyUserDeleting ||
                                        user.status === "PENDING" ||
                                        user.status === "UPDATING",
                                    },
                                  ]
                                : []),
                              {
                                key: "reset",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <RefreshIcon className="w-4" />
                                    Reset the password
                                  </div>
                                ),
                                onClick: (_, close) => {
                                  close();
                                  setDBSUserId(user.id);
                                  setSelectedUser(user);
                                },
                                disabled:
                                  loading ||
                                  isAnyUserDeleting ||
                                  user.status === "PENDING" ||
                                  user.status === "UPDATING",
                              },

                              {
                                type: "divider",
                              },
                              {
                                key: "end",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <ArchiveIcon className="w-4" />
                                    Delete
                                  </div>
                                ),
                                danger: true,
                                onClick: (_, close) => {
                                  close();
                                  setDeleteUser(user);
                                },
                                disabled:
                                  loading ||
                                  isAnyUserDeleting ||
                                  user.status === "PENDING" ||
                                  user.status === "UPDATING",
                              },
                            ]}
                          >
                            <Button
                              uiType="icon"
                              uiSize="small"
                              className="!px-2"
                            >
                              <MoreIcon className="w-4 h-4" />
                            </Button>
                          </Dropdown>
                        </div>

                        {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                      </div>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                ))}

                {users.length === 0 && !usersLoading && (
                  <SimpleTable.Row
                    withoutHoverStyle={true}
                    className="h-[200px]"
                  >
                    <SimpleTable.TD colSpan={10} className="!border-0">
                      <EmptyState text="You have not created any user yet" />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
          <UserDetailsModal />
          <QuestionModal
            title="Reset password"
            description="Are you sure you want to reset the password for this user?"
            isOpen={DBSUserId !== null}
            onClose={() => setDBSUserId(null)}
            onConfirm={() => {
              DBSUserId &&
                service &&
                dispatch(
                  resetDBSUsersAsync({
                    id: service.id,
                    engine: service.engine,
                    userId: DBSUserId,
                  })
                ).then((action) => {
                  if (action.type === "service/users/reset/fulfilled") {
                    dispatch(getDatabaseServicesAsync({}));
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                    dispatch(handleToggleDBSUserResetpasswordModal(true));
                    setResetPassword(action.payload.Result.password);
                  } else {
                  }
                  setDBSUserId(null);
                });
            }}
            loading={userLoading}
            confirmButtonType="primary"
          />
          {selectedUser && (
            <ResetPasswordModal
              resetPassword={resetPassword}
              selectedUser={selectedUser}
            />
          )}

          <DeleteModal
            title="Delete a User"
            isOpen={deleteUser !== null}
            onClose={() => setDeleteUser(null)}
            description=""
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            onConfirm={() => {
              deleteUser &&
                service &&
                dispatch(
                  deleteDBSUserAsync({
                    id: service.id,
                    engine: service.engine,
                    userId: deleteUser.id,
                  })
                ).then((action) => {
                  if (action.type === "service/user/delete/fulfilled") {
                    dispatch(getDatabaseServicesAsync({}));
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                    dispatch(
                      getDBSUsersAsync({
                        id: service.id,
                        engine: service.engine,
                      })
                    );
                  } else {
                  }
                  setDeleteUser(null);
                });
            }}
            loading={userLoading}
            confirmString={deleteUser?.username}
          />
        </>
      )}
    </>
  );
};

export default UserTab;
