import { Outlet } from "react-router-dom";
import { DappPlansUrl, DappStudioUrl } from "../../utils/urls";
import { Helmet } from "react-helmet";
import { ReactComponent as WorkflowIcon } from "./../../assets/icons/workflow.svg";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Typography, Tabs, TabOptions } from "djuno-design";
import useServiceType from "../../hooks/useServiceType";
import DappPlansTab from "../../components/dapp-studio/plans/DappPlansTab";
import DappStudioTab from "../../components/dapp-studio/studio/DappStudioTab";
import {
  DappStudioRefreshStatus,
  getDappStudioAsync,
  selectDappStudio,
  selectDappStudioLoading,
  selectDappStudioStatus,
} from "../../store/dapp-studio/dappStudioSlice";
import { DappGitProvider } from "../../components/dapp-studio/studio/DappStudioIDE/contexts/DappGitContext";
import {
  DappFilesProvider,
  useDappFilesContext,
} from "../../components/dapp-studio/studio/DappStudioIDE/contexts/DappFilesContext";
import { unstable_usePrompt as usePrompt } from "react-router-dom";

export const DappStudioPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <WorkflowIcon className="w-3" />
        Studio
      </div>
    ),
    url: DappStudioUrl,
    element: <DappStudioTab />,
  },
  {
    label: (
      <div className="flex items-center gap-1 plans">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: DappPlansUrl,
    element: <DappPlansTab />,
  },
];

const DappStudioPage = () => {
  const dappStudioStatus = useAppSelector(selectDappStudioStatus);
  const dappStudio = useAppSelector(selectDappStudio);
  const dappStudioLoading = useAppSelector(selectDappStudioLoading);

  const { serviceType } = useServiceType(
    process.env.REACT_APP_DAPP_SERVICE_TYPE
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (
      DappStudioRefreshStatus.includes(dappStudioStatus) &&
      dappStudio !== null &&
      !dappStudioLoading
    ) {
      interval = setInterval(() => {
        dispatch(getDappStudioAsync({ withoutLoading: true }));
      }, 20000);
    } else {
      if (interval) clearInterval(interval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, dappStudioStatus, dappStudio, dappStudioLoading]);

  return (
    <DappGitProvider>
      <DappFilesProvider
        isActive={dappStudio !== null && dappStudioStatus === 2}
      >
        <Helmet>
          <title>
            {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""}
          </title>
          <meta name="description" content="" />
        </Helmet>

        <DappStudioDirtyStatusChacker />
        <div className="flex items-center justify-between h-20">
          <div className="px-6 items-center justify-between flex flex-1 transition duration-150 whitespace-nowrap">
            <Typography.Title level={5} className="!mb-0 ">
              {serviceType?.ServiceName}
            </Typography.Title>
          </div>
          <div className="mr-6 w-96"></div>
        </div>
        <div className="mx-6 flex flex-1 flex-col overflow-auto">
          <div className="flex justify-between">
            <Tabs
              options={DappStudioPageMenus.map((o) => ({
                label: o.label,
                url: o.url,
              }))}
            />
          </div>

          <div className="py-6 flex flex-col flex-1">
            <Outlet />
          </div>
        </div>
      </DappFilesProvider>
    </DappGitProvider>
  );
};

const DappStudioDirtyStatusChacker = () => {
  const { dirtyFiles } = useDappFilesContext();

  usePrompt({
    message: "You have unsaved changes! Do you want to navigate away?",
    when: ({ nextLocation }) => {
      console.log({ nextLocation });
      return (
        !nextLocation.pathname.startsWith("/dapp-studio") &&
        dirtyFiles.length > 0
      );
    },
  });
  return <></>;
};
export default DappStudioPage;
